import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import CommonValidationStatus from "./CommonValidationStatus";
import CommonLandingPage from "./CommonLandingPage";
import OtpValidation from "./OtpValidation";

/**
 * NOTE: Structured Component
 * @description This Component is a new registered user otp page.
 * @return {*}
 */
const NewUserOtpVerification = (props) => {
  const history = useHistory();

  const [isOtpValidated, setOptValidation] = useState(null);
  const [message, setMessage] = useState("");

  const validOtpValidationHandler = (props) => {
    if (isOtpValidated != null) {
      setOptValidation(!setOptValidation);
    } else {
      setOptValidation(props.status);
      setMessage(props.remark);
    }
  };

  const handleLoginButtonClick = () => {
    history.push("login");
  };

  const handleTryAgainButtonClick = () => {
    history.push("createAccount");
  };

  return (
    <CommonLandingPage>
      {isOtpValidated == null && (
        <OtpValidation
          handleClickHandler={validOtpValidationHandler}
          title={"Continue to Condense X"}
          description={
            "One last step before you continue to the application. Enter the OTP sent to your registered Email Address."
          }
          placeholder={"Enter OTP Here"}
          username={props.location.username}
          password={props.location.password}
          emailId={props.location.emailId}
        />
      )}
      {isOtpValidated === true && (
        <CommonValidationStatus
          title={"Account Successfully Created"}
          description={
            "We have successfully created an account for you to use the Condense X application. You’re all set, login to the application using the newly created Username and Password."
          }
          status={"success"}
          buttonPlaceholder={"Login"}
          handleButtonClick={handleLoginButtonClick}
          helperTextRoute={"/support"}
          helperText={"View Docs"}
        />
      )}

      {isOtpValidated === false && (
        <CommonValidationStatus
          title={"Problem Creating Your Account"}
          description={
            message === ""
              ? "There was a problem creating your account. Please check the details, your internet connection and try again later."
              : message
          }
          status={"fail"}
          buttonPlaceholder={"Try Again"}
          handleButtonClick={handleTryAgainButtonClick}
          helperTextRoute={"/"}
          helperText={"Go to Homepage"}
        />
      )}
    </CommonLandingPage>
  );
};

export default withRouter(NewUserOtpVerification);
