import styled from "styled-components";
import ConnectorIcon from "../../assets/images/connector_node_preview_icon.svg";

const CardContainer = styled.div`
  width: 286px;
  height: 168px;
  max-width: 286px;
  max-height: 168px;
  @media (max-height: 800px) {
    height: 118px;
  }

  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: not-allowed;
  box-shadow: 0px 0px 40px 0px #00000029;
`;

const LeftLine = styled.div`
  width: 4px;
  background-color: #47ccd6;
  border-radius: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const ReactFlowNodeCard = (props) => {
  const { title, connectionType, category, description, name } = props.data;
  return (
    <CardContainer>
      <LeftLine />
      <ContentContainer>
        <ContentRow style={{ gap: "8px" }}>
          <IconContainer>
            <img src={ConnectorIcon} alt="Connector Icon" />
          </IconContainer>
          <TitleAndSubtitleContainer>
            {title && (
              <Text
                style={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#F6F6F6",
                  fontFamily: "Articulat CF Medium",
                  letterSpacing: "0.5px",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Text>
            )}
            {name && (
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "14px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  letterSpacing: "0.5px",
                  fontWeight: "bold",
                }}
              >
                {name}
              </Text>
            )}
          </TitleAndSubtitleContainer>
        </ContentRow>
        <ContentRow style={{ gap: "8px" }}>
          <TitleAndSubtitleContainer style={{ width: "55%", maxWidth: "60%" }}>
            <Text
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                color: "#777777",
                fontFamily: "Articulat CF Medium",
                letterSpacing: "0.5px",
                fontWeight: "bold",
              }}
            >
              Category
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                color: "#47CCD6",
                fontFamily: "Articulat CF Medium",
              }}
            >
              {category}
            </Text>
          </TitleAndSubtitleContainer>
          <TitleAndSubtitleContainer style={{ width: "45%", maxWidth: "40%" }}>
            <Text
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                color: "#777777",
                fontFamily: "Articulat CF Medium",
                letterSpacing: "0.5px",
                fontWeight: "bold",
              }}
            >
              Type
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                color: "#47CCD6",
                fontFamily: "Articulat CF Medium",
              }}
            >
              {connectionType}
            </Text>
          </TitleAndSubtitleContainer>
        </ContentRow>
        <ContentRow style={{ gap: "8px" }}>
          <TitleAndSubtitleContainer>
            <Text
              className="multiline-ellipsis"
              style={{
                fontSize: "12px",
                lineHeight: "14px",
                color: "#F6F6F6",
                letterSpacing: "0.5px",
              }}
            >
              {description}
            </Text>
          </TitleAndSubtitleContainer>
        </ContentRow>
      </ContentContainer>
    </CardContainer>
  );
};

export default ReactFlowNodeCard;
