import { useState, useEffect, useCallback } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
// import CommonManufacturerCards from "../../components/onBoardingComponents/_deviceManufacturerCard";
import SearchBox from "../../components/onBoardingComponents/_searchBox";
import DeviceListToConfigure from "../../components/onBoardingComponents/_deviceListToConfigure";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";

const OnBoardingDeviceManufacturer = (props) => {
  const { handleDeviceCardSelection, handleDeviceSelected } = props;
  const [deviceList, setDeviceList] = useState([]);
  const [backUpDeviceList, setBackUpDeviceList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleCardSelect = (
    id,
    name,
    manufact_name,
    devicePort,
    networkType,
    status,
  ) => {
    handleDeviceSelected(name, manufact_name, devicePort, networkType, status);
    if (id === selectedCardId) {
      // Deselect the card if it is already selected
      handleDeviceCardSelection(false);
      setSelectedCardId(null);
    } else {
      // Select the clicked card
      setSelectedCardId(id);
      handleDeviceCardSelection(true);
    }
  };

  const fetchDevices = async () => {
    setIsLoading(true);
    //API Call: Validating OTP and Resetting Password.
    let stringifiedData = JSON.stringify({
      manufacturerId: 31,
    });
    let fetchedDevices = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/getDevicesForManufacturer",
      stringifiedData,
    );
    setDeviceList(fetchedDevices.data.data);
    setBackUpDeviceList(fetchedDevices.data.data);
    setIsLoading(false);
  };

  const handleSearch = (searchedWord) => {
    setIsLoading(true);
    const filteredDevices = backUpDeviceList.filter((device) =>
      device.device_name.toLowerCase().includes(searchedWord.toLowerCase()),
    );

    setDeviceList(searchedWord === "" ? backUpDeviceList : filteredDevices);
    setIsLoading(false);
  };

  return (
    <Row>
      <Col xs={12}>
        <Row className="deviceListPageTitleRow">
          <Col xs={12} md={6} lg={9} className="deviceListPageSubTitle">
            <Typography
              text={"Select the Device Model"}
              tag={"head-x-small"}
              color={"#FAFAFA"}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="onBoardingPipeLineSearchBoxStyle"
          >
            <SearchBox
              placeholder={"Search for Device Models"}
              handleSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row className="deviceListsMainRow">
          <Col xs={12} className="deviceListConfigSkeleton">
            {isLoading ? (
              <SkeletonLoading height={"50vh"} />
            ) : (
              <Row>
                {deviceList.map((eachListItem) => (
                  <Col
                    xs={6}
                    md={4}
                    lg={2}
                    className="deviceListBottomStyling"
                    key={eachListItem.id}
                  >
                    <DeviceListToConfigure
                      name={eachListItem.device_name}
                      id={eachListItem.id}
                      icon_link={eachListItem.icon_link}
                      manufact_name={eachListItem.manufact_name}
                      isSelected={eachListItem.id === selectedCardId}
                      onSelect={handleCardSelect}
                      devicePort={eachListItem.device_port}
                      networkType={eachListItem.network_type}
                      status={eachListItem.status}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default OnBoardingDeviceManufacturer;
