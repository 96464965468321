import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { TourProvider } from "@reactour/tour";
import { workspace } from "./steps";
import CustomTourCard from "./customWalkthrough";
import { AuthProvider } from "./AuthContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
);
