import { useState, useEffect, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import expandIcon from "../../assets/images/expandDropDown.png";
import brightIcon from "../../assets/images/bright.png";
import grafanaIcon from "../../assets/images/grafana.png";
import grafanaClickedIcon from "../../assets/images/grafana_icon.svg";
import notification from "../../assets/images/notifications.png";
import launch from "../../assets/images/launchnew.png";
import AccountDropDown from "../common/_accountDropDown";
import WorkSpaceDropDown from "./WorkSpaceDropDown";
import LeftArrow from "../../../src/assets/images/leftArrow.png";
import { arrData } from "./NavBarUtils";
import NavBarCommonDropdown from "./NavBarCommonDropdown";
import GrafanaCredentialsModal from "./GrafanaCredentialsModal";

//Navbar Context
export const NavbarContext = createContext();
export let navbarSharedData = {};

const NavBar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [iconText, setIconText] = useState("");
  const [isAccountDropDown, setAccountDropDown] = useState(false);
  const [isWorkSpaceDropDown, setWorkSpaceDropDown] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [selectedSpaceId, setSelectedSpaceId] = useState("");
  const [selectedSpaceIconLink, setSelectedSpaceIconLink] = useState("");
  const [isGrafanaIconClicked, setIsGrafanaIconClicked] = useState(false);
  const [selectedNavbarItem, setSelectedNavbarItem] = useState(null);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState("");
  const [workSpaces, setWorkSpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGrafanaModalOpen, setGrafanaModalOpen] = useState(false);

  useEffect(() => {
    fetchWorkSpaces();
  }, [selectedSpaceId]);

  const fetchWorkSpaces = async () => {
    setIsLoading(true);
    let fetchWorkSpacesResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
    );

    setWorkSpaces(fetchWorkSpacesResponse.data.data);

    const getWorkSpaceNameFromLocal = localStorage.getItem(
      "selectedWorkSpaceName",
    );
    const getWorkSpaceIconLinkFromLocal = localStorage.getItem(
      "selectedWorkSpaceIconLink",
    );

    setSelectedSpace(
      getWorkSpaceNameFromLocal ?? fetchWorkSpacesResponse.data.data[0]?.name,
    );
    setSelectedSpaceIconLink(
      getWorkSpaceIconLinkFromLocal ??
        fetchWorkSpacesResponse.data.data[0]?.icon_link,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    const iconTextFromLocalStorage = localStorage.getItem("userName");

    if (iconTextFromLocalStorage?.length > 2) {
      setIconText(iconTextFromLocalStorage.slice(0, 2).toUpperCase());
    } else {
      setIconText("AA");
    }
  }, []);

  // const workSpaceDropDown = (event) => {
  //   // event.stopPropagation()
  //   setWorkSpaceDropDown(!isWorkSpaceDropDown);
  // };
  const openWorkSpaceDropDown = () => {
    setWorkSpaceDropDown(true);
  };

  const closeWorkSpaceDropDown = () => {
    setWorkSpaceDropDown(false);
  };

  const handleWorkSpaceChange = (
    selectedWorkSpaceName,
    selectedWorkSpaceId,
    selectedWorkSpaceIconLink,
  ) => {
    localStorage.setItem("selectedWorkSpaceName", selectedWorkSpaceName);
    localStorage.setItem(
      "selectedWorkSpaceIconLink",
      selectedWorkSpaceIconLink,
    );

    setSelectedSpaceId(selectedWorkSpaceId);

    setWorkSpaceDropDown(!isWorkSpaceDropDown);

    history.push({
      pathname: "connectorsPlayground",
      selectedWorkspace: selectedWorkSpaceId,
    });
  };

  const accountDropDown = () => {
    setAccountDropDown(!isAccountDropDown);
  };

  const handleNavbarSelection = (navbarItemName) => {
    console.log("navbarItemName:::", navbarItemName);
    setSelectedNavbarItem(navbarItemName);
  };

  useEffect(() => {
    switch (selectedNavbarItem) {
      case "Pipelines":
        history.push("/pipelines");
        break;
      case "Templates":
        history.push("/templates");
        break;
      case "Connectors":
        history.push("/connectors");
        break;
      case "Windows":
        history.push("/windows");
        break;
      case "Documentation":
        history.push("/documentation");
        break;
      case "Support":
        history.push("/navSupport");
        break;
      case "Settings":
        history.push("/settings");
        break;
      default:
        break;
    }
  }, [selectedNavbarItem]);

  const handleLogoClick = () => {
    history.push({
      pathname: "onBoarding",
      navBarOnBoardingState: 2,
    });
  };
  const handleGrafanaIconClick = () => {
    setGrafanaModalOpen(!isGrafanaModalOpen);
    setIsGrafanaIconClicked(!isGrafanaIconClicked);
  };

  const grafanaModalClose = () => {
    setGrafanaModalOpen(false);
  };

  useEffect(() => {
    // Going back to onBaording page, when browser back button is clicked
    window.addEventListener("popstate", function (event) {
      handleLogoClick();
    });
  });

  //Pass States and Functions here, you want to share with all Child Components
  navbarSharedData = {
    setSelectedDropDownItem,
    handleNavbarSelection,
  };

  return (
    <NavbarContext.Provider value={navbarSharedData}>
      <div className="navBarStyle">
        <div className="navBarTopRow">
          <div className="navBarleftArea">
            <div className="navBarLogoArea">
              <div
                className="navBarCondenseLogo"
                onClick={handleLogoClick}
                tour-guide="welome_workspace"
              >
                <img
                  className="condenseLogoIcon"
                  width="145"
                  height="25"
                  src={condenseLogo}
                  alt="condenseLogo"
                />
              </div>

              {localStorage.selectedWorkSpaceName && (
                <>
                  <div className="navBarWorkspaceLine"></div>
                  <div className="navBarWorkSpaceParent">
                    <div className="navBarWorkspaceLogo">
                      <img src={selectedSpaceIconLink} alt="" />
                    </div>
                    <p
                      className="navBarWorkspaceName"
                      style={{
                        fontFamily: "Articulat CF Medium",
                        letterSpacing: "0.5px",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedSpace}
                    </p>
                    <div className="navBarWorkspaceDropDown">
                      {isWorkSpaceDropDown && (
                        <img
                          src={expandIcon}
                          onClick={closeWorkSpaceDropDown}
                          alt="expandIcon"
                        />
                      )}
                      {!isWorkSpaceDropDown && (
                        <img
                          src={expandIcon}
                          onClick={openWorkSpaceDropDown}
                          // onClick={workSpaceDropDown}
                          alt="expandIcon"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}

              {isWorkSpaceDropDown && (
                <div className="workSpaceDropDownContainerPosition">
                  <div className="workSpaceDropDownContentPosition">
                    <WorkSpaceDropDown
                      workSpaces={workSpaces}
                      handleWorkSpaceChange={handleWorkSpaceChange}
                      closeDropdown={closeWorkSpaceDropDown}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* FIXME: Free plan has been commented for deployment */}
            {/* <div className="navBarFreePlanCard">
              <div className="navBarCardContent">
                <div className="navBarCardIcon">
                  Free Plan{" "}
                  <img className="navBarFreePlanIcon" src={launch} alt="" />
                </div>
              </div>
            </div> */}
          </div>

          <div className="navBarProfileArea">
            <div className="navBarRightAreaParent">
              <div className="navBarRightArea">
                <img
                  className="navBarWebStory"
                  height="24"
                  width="24"
                  src={isGrafanaIconClicked ? grafanaClickedIcon : grafanaIcon}
                  alt=""
                  onClick={handleGrafanaIconClick}
                />
              </div>
              <div className="rightProfileArea">
                <div className="navBarProfileAvatar">
                  <p>{iconText}</p>
                </div>
                <div className="navBarProfileDownArrow">
                  <img
                    src={expandIcon}
                    onClick={accountDropDown}
                    alt="expandIcon"
                  />
                </div>
              </div>
            </div>
            {isAccountDropDown && (
              <div className="navBarAccountDropDownContainer">
                <div className="navBarAccountDropDownContent">
                  <AccountDropDown />
                </div>
              </div>
            )}
            {isGrafanaModalOpen && (
              <GrafanaCredentialsModal grafanaModalClose={grafanaModalClose} />
            )}
          </div>
        </div>

        {/* <div className="navBarBottomRow">
          <div className="navBarBottomLeftArea">
            {arrData.map((eachData) => {
              return (
                <div
                  key={eachData.id}
                  className="navBarBottomLeftComponentsArea"
                  style={{
                    opacity:
                      eachData.name == "Connectors" &&
                      location.pathname === "/onBoarding"
                        ? "0.7"
                        : null,
                    pointerEvents:
                      eachData.name == "Connectors" &&
                      location.pathname === "/onBoarding"
                        ? "none"
                        : null,
                  }}
                  onClick={() => handleNavbarSelection(eachData.name)}
                >
                  <div className="navBarBottomIcons">
                    <img src={eachData.logo} alt="" />
                  </div>
                  <p
                    style={{
                      position: "relative",
                      color:
                        selectedNavbarItem === eachData.name
                          ? "#47CCD6"
                          : "white",
                    }}
                  >
                    {eachData.name}
                  </p>
                  {eachData.dropDown ? (
                    <div>
                      <img src={expandIcon} alt="expandIcon" />
                    </div>
                  ) : null}
                  {eachData.dropDown &&
                    selectedNavbarItem === eachData.name && (
                      <NavBarCommonDropdown name={eachData.name} />
                    )}
                </div>
              );
            })} */}
        {/* </div> */}
        {/* <div
            className="navBarBottomRightArea"
            onClick={() => handleNavbarSelection("Settings")}
          >
            <div className="navBarBottomIcons">
              <img src={SettingsIcon} alt="" />
            </div>
            <p className="navBarBottomSettings">Settings</p>
          </div> */}
      </div>
      {/* </div> */}
      {/* </div> */}
    </NavbarContext.Provider>
  );
};

export default NavBar;
