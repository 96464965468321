import { useHistory, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import React, { useState } from "react";
import CommonLandingPage from "./CommonLandingPage";
import jwtDecode from "jwt-decode";
import { setItem } from "../../storage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import Column from "../../components/common/_customColumn";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

/**
 * NOTE: Structured Component
 * @description This Component is a user login page.
 * @return {*}
 */

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 16px;
  font-weight: 500;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 11px 12px;
  width: 100%;
  border-radius: 12px;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: none;
  padding: 13px;
  color: #ececec;
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  &:disabled {
    opacity: 0.5;
    background: #5e5e5e;
  }
  &:not(:disabled) {
    &:hover {
      background: #40909a;
      border-color: #40909a;
    }

    &:active {
      background: #47ccd6;
      border-color: #47ccd6;
    }
  }
`;

const Login = () => {
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const checkUserName = () => {
    const userNameRegex1 = new RegExp(/^[a-zA-Z0-9]{6,15}$/);
    const userNameRegex2 = new RegExp(/^\d+$/);

    if (
      userNameRegex1.test(username) === false ||
      userNameRegex2.test(username) === true
    ) {
      setErrorMessage(
        "Username must be 6 to 15 characters, and alphanumeric or alphabets only.",
      );
      return false;
    } else {
      return true;
    }
  };

  const checkPassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/,
    );

    if (passwordRegex.test(password) === false) {
      setErrorMessage(
        "Password must have 8 to 15 aplhanumeric characters, one capital letter, special characters allowed are !@#$%^&*_",
      );
      return false;
    } else {
      return true;
    }
  };

  const onClickEvent = async () => {
    let checkPasswordResponse;
    setErrorMessage("");

    let checkUserNameResponse = await checkUserName();

    if (checkUserNameResponse === true) {
      checkPasswordResponse = await checkPassword();
    }

    if (checkUserNameResponse === true && checkPasswordResponse === true) {
      let stringifiedData = JSON.stringify({
        userName: username,
        password: password,
      });

      //API Call: To fetch login Token.
      try {
        let generateLoginTokenResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/generateLoginToken",
          stringifiedData,
        );

        if (generateLoginTokenResponse.data.code === 200) {
          let token = generateLoginTokenResponse.data.data.token;

          const { loginId, roles, userName } = jwtDecode(token);

          //Setting up local storage
          setItem("loginId", loginId, "PERSISTENT");
          setItem("userName", userName, "PERSISTENT");
          setItem("roles", roles, "PERSISTENT");
          setItem("token", token, "PERSISTENT");

          history.push("onBoarding");
        }
      } catch (err) {
        setErrorMessage(err.response.data.remarks);
      }
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <CommonLandingPage>
      <Col>
        <CustomRow>
          <Column style={{ marginBottom: "40px" }}>
            <Text
              style={{ color: "#F6F6F6", fontSize: "22px", lineHeight: "31px" }}
            >
              Welcome Back! Login to Continue
            </Text>
          </Column>
          <Column style={{ marginBottom: "16px" }}>
            <InputFieldContainer
              style={errorMessage ? { borderColor: "#EA3A3A" } : {}}
            >
              <InputField
                placeholder="Username/Email"
                name="username"
                onChange={handleUsernameChange}
              />
            </InputFieldContainer>
          </Column>
          <Column style={{ marginBottom: errorMessage ? "10px" : "40px" }}>
            <InputFieldContainer
              style={errorMessage ? { borderColor: "#EA3A3A" } : {}}
            >
              <InputField
                placeholder="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
              />
              <img
                src={showPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                alt="Show Password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  height: "18px",
                  width: "18px",
                }}
              />
            </InputFieldContainer>
          </Column>
          {errorMessage && (
            <Column style={{ marginBottom: "12px" }}>
              <Text style={{ color: "#EA3A3A", fontSize: "12px" }}>
                {errorMessage}
              </Text>
            </Column>
          )}
          <Column style={{ marginBottom: "16px" }}>
            {/* <StyledButton
              onClick={onClickEvent}
              disabled={!username || !password}
            >
              Sign In
            </StyledButton> */}
            <StyledButtonPrimary
              style={{ fontFamily: "Articulat CF Medium", width: "100%" }}
              onClick={onClickEvent}
              disabled={!username || !password}
            >
              Sign In
            </StyledButtonPrimary>
          </Column>
          <Column>
            <Link to="/forgetPassword">
              <Text
                style={{
                  fontSize: "16px",
                  lineHeight: "21px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#CCCCCC",
                  cursor: "pointer",
                }}
              >
                Forgot Username/Password?
              </Text>
            </Link>
          </Column>
          <Column>
            <Link to="/createAccount">
              <Text
                style={{
                  fontSize: "16px",
                  paddingTop: "16px",
                  lineHeight: "21px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#CCCCCC",
                  cursor: "pointer",
                }}
              >
                Create New User
              </Text>
            </Link>
          </Column>
        </CustomRow>
      </Col>
    </CommonLandingPage>
  );
};

export default withRouter(Login);
