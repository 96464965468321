import React from "react";
import ReactFlow, { Background, BackgroundVariant } from "reactflow";
import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import PipelineIcon from "../../assets/images/pipeline_bg.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 47px;
  line-height: 41.6px;
  text-align: left;
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-weight: bold;
`;
const Description = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 24px;
  line-height: 31.2px;
  text-align: left;
  color: #cccccc;
  font-weight: lighter;
`;
const Button = styled.div`
  height: 40px;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ButtonText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #f6f6f6;
`;
const PipeLinePageContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow: none;
  /* overflow-y: auto; */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const ImageArea = styled.div`
  height: calc(100vh - 372px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
`;
const Icon = styled.img``;
const PipelinesMainPage = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/connectors");
  };
  return (
    <PipeLinePageContent>
      <Content>
        <StyledRow>
          <Column xs={10} md={7}>
            <Title>Ready to create your pipeline?</Title>
          </Column>
        </StyledRow>
        <StyledRow>
          <Column xs={5.5} md={5.5}>
            <Description>
              You currently have no connectors added. Click on the button below
              to get started!
            </Description>
          </Column>
        </StyledRow>
        <StyledRow>
          <Column xs={2.4}>
            {/* <Button
              style={{ backgroundColor: "#47ccd6" }}
              onClick={handleClick}
            >
              <ButtonText>Add Connector</ButtonText>
            </Button> */}
            <StyledButtonPrimary
              onClick={handleClick}
              style={{ height: "48px", width: "240px" }}
            >
              Add Connector
            </StyledButtonPrimary>
          </Column>
        </StyledRow>
      </Content>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={PipelineIcon} alt="img" width="100%" height="auto" />
      </div>
    </PipeLinePageContent>
  );
};

export default PipelinesMainPage;
