import styled from "styled-components";
import backIcon from "../../assets/images/left_arrow_icon.svg";
// import configIcon from "../../assets/images/Configure.svg";
// import configSettingIcon from "../../assets/images/Configure-settings.png";
import Stepper from "../../pages/WorkSpaceConnectors/Stepper";

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const NavbarLeftConnect = styled.div`
  display: flex;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
`;

const NavbarRightConnect = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  font-weight: 600;
`;

const Icon = styled.div`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const SelectIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #41bac3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const ConfigRightConnectSelect = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  gap: 16px;
`;

const ConfigRightConnectDeploy = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 16px;
`;

const arrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="8.9"
    viewBox="0 0 50 8.9"
  >
    <path
      fill="#FAFAFA"
      d="M50 4.2L45.8 0l-1.4 1.4L47 4.2H0v1.4h47L44.4 8.4l1.4.5 4.2-4.2z"
    />
  </svg>
);

const ConfigBottomNavbar = ({
  connectorName,
  handleBackClick,
  currentStep,
  setCurrentStep,
  setAnimatingLine,
  animatingLine,
}) => {
  const handleConnectorsClick = () => {
    handleBackClick();
  };

  return (
    <NavbarContainer>
      <NavbarLeftConnect>
        <Icon onClick={handleConnectorsClick}>
          <img src={backIcon} alt="Back Icon" />
        </Icon>
        <Title>{`Configure ${connectorName || "your connector"}`}</Title>
      </NavbarLeftConnect>
      {/* <NavbarRightConnect>
        <ConfigRightConnectSelect>
          <SelectIcon>
            <img src={configIcon} alt="Config Icon" />
          </SelectIcon>
          <Title>Select Connector</Title>
        </ConfigRightConnectSelect>
        {arrowIcon}
        <ConfigRightConnectDeploy>
          <SelectIcon>
            <img src={configSettingIcon} alt="Config Setting Icon" />
          </SelectIcon>
          <Title>Configure &amp; Deploy Connector</Title>
        </ConfigRightConnectDeploy>
      </NavbarRightConnect> */}
      <Stepper
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setAnimatingLine={setAnimatingLine}
        animatingLine={animatingLine}
      />
    </NavbarContainer>
  );
};

export default ConfigBottomNavbar;
