import React from "react";
import styled from "styled-components";

const CustomRoundedButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: white;
  padding: ${(props) => props.padding || "12px 40px"};
  background: ${(props) => (props.disabled ? "#2A5F63" : "#47CCD6")};
  width: ${(props) => props.width || "50%"};
  &:hover {
    background: #40909a;
  }
  &:active {
    background: #47ccd6;
  }
`;

/**
 * Renders a rounded button component with the provided text, onClick event handler,
 * disabled state, width, padding, and color.
 *
 * @param {object} props - The props object containing the following properties:
 *   - text {string} - The text to be displayed on the button.
 *   - onClick {function} - The event handler for the button click event.
 *   - disabled {boolean} - Indicates whether the button is disabled or not.
 *   - width {string} - The width of the button.
 *   - padding {string} - The padding of the button.
 *   - color {string} - The color of the button.
 * @return {JSX.Element} - The rendered rounded button component.
 */
function Roundedbutton({ text, onClick, disabled, width, padding, color }) {
  return (
    <CustomRoundedButton
      onClick={onClick}
      text={text}
      width={width}
      padding={padding}
      color={color}
    >
      {text}
    </CustomRoundedButton>
  );
}

export default Roundedbutton;
