import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import CommonLandingPage from "./CommonLandingPage";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import styled from "styled-components";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

/**
 * NOTE: Structured Component
 * @description This Component is for entering OTP page, related to Forget Password.
 * @return {*}
 */

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  text-align: center;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: 1px solid #47ccd6;
  padding: 13px;
  color: #ececec;
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  &:disabled {
    opacity: 0.5;
  }
`;

const ForgetPasswordOtpVerification = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [isOtpInvalid, setOtpInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpValidationLoading, setOtpValidationLoading] = useState(false);

  // Layout effect to set the first input focus
  useLayoutEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  if (!location.state?.email) {
    history.push("/login");
  }

  const handleOtpChange = (element, index) => {
    if (isNaN(element)) return false;
    if (element === " ") return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element : d))]);

    // Focus next input
    if (element !== "") {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpKeydown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyClick = async () => {
    setOtpInvalid(false);
    setOtpValidationLoading(true);
    try {
      let stringifiedData = JSON.stringify({
        clientId: env.REACT_APP_CLIENT_ID,
        otp: otp.join(""),
      });

      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/verifyOtp",
        stringifiedData,
      );

      setOtpValidationLoading(false);
      history.push("/resetPassword", {
        email: location.state.email,
        otp: otp.join(""),
      });
    } catch (err) {
      console.log("err verifyOtp", err);
      setOtpValidationLoading(false);
      if (err?.response?.data?.code === 400) {
        setErrorMessage(err?.response?.data.remarks);
        setOtpInvalid(true);
      } else {
        setErrorMessage("Unable to verify OTP. Please try again later.");
      }
    }
  };

  const handleOtpResend = async () => {
    setOtpValidationLoading(true);
    try {
      let stringifiedData = JSON.stringify({
        clientId: env.REACT_APP_CLIENT_ID,
        emailId: location.state.email,
      });

      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/generateOtp",
        stringifiedData,
      );

      setOtpValidationLoading(false);
      showMessage("Successfully resent OTP.", "success");
    } catch (err) {
      setOtpValidationLoading(false);
      console.log("error in resend otp", err);
      showMessage("Failed to resend OTP.");
    }
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: "40px" }}>
          <Text
            style={{ color: "#F6F6F6", fontSize: "22px", lineHeight: "31px" }}
          >
            Please obtain the otp from admin. We have sent an OTP to admin
            email.
          </Text>
        </Column>
        <Column style={{ marginBottom: errorMessage ? "10px" : "40px" }}>
          <CustomRow>
            {otp.map((num, index) => (
              <Column xs={1.8} key={index}>
                <InputFieldContainer
                  style={isOtpInvalid ? { borderColor: "#EA3A3A" } : {}}
                >
                  <InputField
                    placeholder="0"
                    maxLength={1}
                    value={num}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleOtpKeydown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                </InputFieldContainer>
              </Column>
            ))}
          </CustomRow>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: "12px" }}>
            <Text style={{ color: "#EA3A3A", fontSize: "12px" }}>
              {errorMessage}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: "16px" }}>
          <StyledButton
            disabled={otp.some((d) => d === "") || isOtpValidationLoading}
            onClick={() => handleVerifyClick()}
          >
            Verify
          </StyledButton>
        </Column>
        <Column style={{ marginBottom: "50px" }}>
          <Text
            style={{
              color: "#777777",
              fontSize: "16px",
              lineHeight: "20.8px",
            }}
          >
            Didn’t get a code?{" "}
            <span
              style={{
                color: "#CCCCCC",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => !isOtpValidationLoading && handleOtpResend()}
            >
              Click to Resend
            </span>
          </Text>
        </Column>
        <Column>
          <Link to="/login">
            <Text style={{ color: "#CCCCCC", fontSize: "16px" }}>
              <img
                src={ChevronRight}
                alt="chevron"
                style={{ height: "12px", width: "12px", marginRight: "10px" }}
              />
              Back to login
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default ForgetPasswordOtpVerification;
