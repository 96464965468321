import { Container, Col, Row } from "reactstrap";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";

const CommonOnboardingBackground = (props) => {
  return (
    <CustomRow className="onBoardingPageMainStyle">
      <>
        <Column xs={12}>{props.children}</Column>
      </>
    </CustomRow>
  );
};

export default CommonOnboardingBackground;
