import { useContext, useEffect, useState } from "react";
import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import styled from "styled-components";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import GenericSelection from "../../components/common/_dropDownComponent";
import Repository from "../../assets/images/Repository.svg";
import Branch from "../../assets/images/git-branch.svg";
import Norepository from "../../assets/images/Illustrationnorepo.svg";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const MainContainer = styled.div`
  background-color: #171717;
  height: 100vh;
  width: 100%;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const Text = styled.p`
  color: #f6f6f6;
  font-size: 24px;
  margin: 0;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const Content = styled.div`
  font-family: Articulat CF Medium;
  font-size: 24px;
  line-height: 31.2px;
  letter-spacing: -0.02em;
  color: #cccccc;
  width: 437px;
`;

const RepoInformation = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  // States variable
  const [isLoading, setLoading] = useState(true);
  const [allRepos, setAllRepos] = useState([]);
  const [gitUserName, setGitUserName] = useState(null);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [gitData, setGitData] = useState(null);

  const getAllbranchesOfRepo = async (selectedRepo, workspaceName) => {
    const workspaceId = parseInt(
      localStorage.getItem("selectedWorkSpaceId") || 0,
    );
    setLoading(true);
    try {
      const stringifiedData = {
        gitUsername: gitUserName,
        gitRepo: selectedRepo.repoName,
        repoId: selectedRepo.repoId,
        workspaceName: workspaceName,
        repositoryName: selectedRepo.repoName,
        workSpaceId: workspaceId,
        applicationId: location.state?.applicationId,
      };
      let getBranchesResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllBranchesOfRepo",
        stringifiedData,
      );
      setAllBranches(getBranchesResponse.data.allBranches || []);
      setLoading(false);
    } catch (error) {
      console.log("Error: getAllBranchesOfRepo", error);
      showMessage("Unable to get the branch information");
      setLoading(false);
    }
  };

  const getAllRepo = async (workspaceId, applicationId) => {
    try {
      const stringifiedData = {
        workSpaceId: workspaceId,
        applicationId,
      };
      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllRepo",
        stringifiedData,
      );
      setAllRepos(response.data.allRepos);
      setGitUserName(response.data.gitUsername);
      setLoading(false);
    } catch (err) {
      console.log("Error: getAllRepo", err);
      showMessage("Unable to get the repository information");
      setLoading(false);
    }
  };

  const handleSelectRepo = (item) => {
    setSelectedRepo(item);
    setSelectedBranch(null);
    getAllbranchesOfRepo(item, item?.workspaceName);
  };

  const handleSelectBranch = (item) => {
    setSelectedBranch(item);
  };

  const handleNextClick = () => {
    setLoading(true);
    try {
      const workspaceId = parseInt(
        localStorage.getItem("selectedWorkSpaceId") || 0,
      );
      let payload = {
        username: gitUserName,
        repoName: selectedRepo.repoName,
        branchName: selectedBranch.branchName,
        workspaceId: workspaceId,
        applicationId: location.state?.applicationId,
        worksapceName: selectedRepo.workspaceName,
      };

      (async () => {
        let cloneResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "debug/cloneRepo",
          payload,
        );

        if (cloneResponse.data?.code === 200) {
          setLoading(false);
          history.push("/codeEditor", {
            applicationId: location.state?.applicationId,
            isRedirected: true,
            gitUsername: gitUserName,
            repositoryName: selectedRepo.repoName,
          });
        } else {
          throw new Error("Failed to clone the repository");
        }
      })();
    } catch (err) {
      console.log("Error: cloneRepo", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the Application Details !!!");
      }
      setLoading(false);
    }
  };

  // On Mount of this page, call the API to get the status of the Application
  useEffect(() => {
    const workspaceId = parseInt(
      localStorage.getItem("selectedWorkSpaceId") || 0,
    );
    if (!workspaceId) {
      history.push("/");
    }

    (async () => {
      try {
        let appDetailsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getApplicationData",
          {
            applicationId: location.state?.applicationId,
            workspaceId,
          },
        );
        setGitData({
          gitUsername: appDetailsResponse.data?.data?.gitUsername,
          authProvider: appDetailsResponse.data?.data?.authProvider,
          avatarUrl: appDetailsResponse.data?.data?.avatarUrl,
        });
        if (appDetailsResponse.data?.data?.applicationStatus) {
          if (appDetailsResponse.data?.data?.applicationStatus === 1) {
            showMessage("POD for the application is not deployed yet", "error");
          } else if (appDetailsResponse.data?.data?.applicationStatus === 2) {
            getAllRepo(workspaceId, location.state?.applicationId);
          } else {
            history.push("/codeEditor", {
              applicationId: location.state?.applicationId,
              isRedirected: true,
              gitUsername: appDetailsResponse.data?.data?.gitUsername,
              ...appDetailsResponse.data?.data,
            });
          }
        } else {
          throw new Error(
            "Unable to obtain the application status in response",
          );
        }
      } catch (err) {
        console.log("Error: getApplicationData", err);

        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch the Application Details !!!");
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Is the page is not opened through redirection internally, then redirect to home
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  return isLoading ? (
    <ParentContainer>
      <AnimationContainer>
        <ScrollAnimationBar />
        <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
      </AnimationContainer>
    </ParentContainer>
  ) : (
    <MainContainer>
      <CustomRow alignItems="normal" style={{ overflow: "auto" }}>
        <Column
          xs={12}
          style={{
            padding: "28px 16px",
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: "1px solid #2f2f2f",
          }}
        >
          <img
            src={BackIcon}
            style={{ height: "32px", width: "32px", cursor: "pointer" }}
            alt="Go Back"
            onClick={() => history.push("/applications")}
          />
          <Text
            style={{
              fontFamily: "Articulat CF Medium",
              letterSpacing: "0.5px",
              fontWeight: "bold",
            }}
          >
            Choose Repository and Branch to continue
          </Text>
        </Column>
        <Column xs={12} md={4} style={{ borderRight: "1px solid #2f2f2f" }}>
          <CustomRow>
            {gitData && (
              <Column
                xs={12}
                style={{ padding: "16px", borderBottom: "1px solid #2f2f2f" }}
              >
                <CustomRow>
                  <Column xs={2}>
                    <img
                      src={gitData.avatarUrl}
                      alt="Avatar"
                      style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "8px",
                      }}
                    />
                  </Column>
                  <Column
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Articulat CF",
                        fontWeight: "bold",
                        lineHeight: "28px",
                      }}
                    >
                      {gitData.authProvider.charAt(0).toUpperCase() +
                        gitData.authProvider.slice(1)}
                    </Text>
                    <Text
                      style={{
                        color: "#B0B0B0",
                        fontFamily: "Articulat CF Regular",
                        letterSpacing: "0.5px",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "14px",
                      }}
                    >
                      {gitData.gitUsername}
                    </Text>
                  </Column>
                </CustomRow>
              </Column>
            )}
            <Column xs={12} style={{ padding: "0 16px" }}>
              <GenericSelection
                icon={Repository}
                dropdownItems={allRepos}
                selectedItem={selectedRepo}
                title="Select Repository"
                placeholder="Search for Repository"
                onSelect={handleSelectRepo}
                nameFunction={(item) => item.repoName}
                filterFunction={(arr, text) =>
                  arr.filter((item) =>
                    item.repoName.toLowerCase().includes(text.toLowerCase()),
                  )
                }
              />
            </Column>
            <Column xs={12} style={{ padding: "0 16px", marginBottom: "20px" }}>
              <GenericSelection
                icon={Branch}
                dropdownItems={allBranches}
                selectedItem={selectedBranch}
                title="Select Branch"
                placeholder="Search for Branch"
                onSelect={handleSelectBranch}
                nameFunction={(item) => item.branchName}
                filterFunction={(arr, text) =>
                  arr.filter((item) =>
                    item.branchName.toLowerCase().includes(text.toLowerCase()),
                  )
                }
                disable={!allBranches || allBranches.length === 0}
              />
            </Column>
            <Column xs={12} style={{ padding: "0 16px" }}>
              <StyledButtonPrimary
                disabled={
                  !Boolean(
                    location.state &&
                      selectedRepo &&
                      selectedBranch &&
                      gitUserName,
                  )
                }
                style={{ width: "100%" }}
                onClick={handleNextClick}
              >
                Next
              </StyledButtonPrimary>
            </Column>
          </CustomRow>
        </Column>
        <Column xs={12} md={8}>
          <div
            style={{
              height: "100%",
              width: "100%",
              padding: "24px 32px 24px 24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title style={{ fontSize: "48px", lineHeight: "62.4px" }}>
              {" "}
              Get Started...
            </Title>
            <Content style={{ paddingTop: "16px" }}>
              Select Repository and Branch to build and publish your first
              custom application!
            </Content>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "27px",
                height: "calc(100% - 149px)",
              }}
            >
              <img src={Norepository} alt="no repo" />
            </div>
          </div>
        </Column>
      </CustomRow>
    </MainContainer>
  );
};

export default RepoInformation;
