import React from "react";
import styled from "styled-components";
import LeftArrow from "../../assets/images/leftArrow.png";
import LoginCard from "./components/LoginCard";
import Github from "../../assets/images/Github.svg";
import Gitlab from "../../assets/images/Gitlab.svg";
import BitBucket from "../../assets/images/Bitbucket.svg";
import { env } from "../../env";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Container = styled.div`
  background-color: black;
  height: 100%;
`;
const HeaderComponent = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  color: #ffffff;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-size: 24px;
  line-height: 41.6px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;
const Logo = styled.div`
  padding-right: 16px;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 32px;
`;

const Logincardcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 24px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 60px;
`;
const LoginCards = [
  {
    name: "Github",
    icon: Github,
  },
  {
    name: "Gitlab",
    icon: Gitlab,
  },
  {
    name: "Bitbucket",
    icon: BitBucket,
  },
];

function NewApplicationPage() {
  const history = useHistory();
  const handleLogin = async (platform) => {
    switch (platform) {
      case "Github":
        window.location.assign(
          `https://github.com/login/oauth/authorize?client_id=${
            env.REACT_APP_GITHUB_OAUTH_CLIENT_ID
          }&scope=repo&redirect_uri=${
            env.REACT_APP_OAUTH_REDIRECTION_URL
          }&state=${encodeURI(window.location.origin)}/oauth/authorize|github`,
        );
        break;
      case "Gitlab":
        const gitlabUrl = `https://gitlab.com/oauth/authorize?client_id=${
          env.REACT_APP_GITLAB_OAUTH_CLIENT_ID
        }&redirect_uri=${
          env.REACT_APP_OAUTH_REDIRECTION_URL
        }&response_type=code&scope=api+read_api+read_user+read_repository+write_repository&state=${encodeURI(
          window.location.origin,
        )}/oauth/authorize|gitlab`;
        window.location.assign(gitlabUrl);
        break;
      case "Bitbucket":
        const bitbucketUrl = `https://bitbucket.org/site/oauth2/authorize?client_id=${
          env.REACT_APP_BITBUCKET_OAUTH_CLIENT_ID
        }&redirect_uri=${
          env.REACT_APP_OAUTH_REDIRECTION_URL
        }&response_type=code&state=${encodeURI(
          window.location.origin,
        )}/oauth/authorize|bitbucket`;
        window.location.assign(bitbucketUrl);
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Content>
        <HeaderComponent>
          <Logo onClick={() => history.push("/applications")}>
            <img src={LeftArrow} alt="arrow" />
          </Logo>
          <Title>Add a New Custom Connector (Application)</Title>
        </HeaderComponent>
        <LoginContainer>
          <Title>Connect your Code Repository</Title>
          <Logincardcontainer>
            {LoginCards.map((card, index) => (
              <LoginCard
                name={card.name}
                icon={card.icon}
                handleLogin={handleLogin}
                key={index}
              />
            ))}
          </Logincardcontainer>
        </LoginContainer>
      </Content>
    </Container>
  );
}

export default NewApplicationPage;
