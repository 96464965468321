import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const OtpContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OtpInput = styled.input`
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 20px;
  background-color: #2f2f2f;
  border-radius: 8px;
  margin: 0 5px;
  outline: none;
  border: 2px solid #2f2f2f;
  color: #f6f6f6;
  &:focus {
    outline: none;
    border: 2px solid #2f2f2f;
  }
`;

function OtpInputField({ onChange }) {
  const numberOfDigits = 6;
  const otpBoxReference = useRef([]);
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));

  useEffect(() => {
    otpBoxReference.current = otpBoxReference.current.slice(0, numberOfDigits);
  }, []);

  const focusNextInput = (index) => {
    const nextIndex = index + 1;
    if (nextIndex < numberOfDigits) {
      otpBoxReference.current[nextIndex].focus();
    }
  };

  const focusPrevInput = (index) => {
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      otpBoxReference.current[prevIndex].focus();
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const parsedValue = parseInt(value, 10);
    let newArr = [...otp];
    newArr[index] = value;
    if (!isNaN(parsedValue) && value.length === 1) {
      setOtp(newArr);
      focusNextInput(index);
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && index >= 0) {
      let newArr = [...otp];
      newArr[index] = "";
      setOtp(newArr);
      focusPrevInput(index);
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      focusNextInput(index);
    }
  };

  useEffect(() => {
    onChange(otp.join(""));
  }, [otp]);

  return (
    <OtpContainer>
      {Array(numberOfDigits)
        .fill(0)
        .map((_, index) => (
          <OtpInput
            key={index}
            type="text"
            maxLength="1"
            placeholder="0"
            id={`otp-input-${index}`}
            value={otp[index]}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(ref) => (otpBoxReference.current[index] = ref)}
          />
        ))}
    </OtpContainer>
  );
}

export default OtpInputField;
