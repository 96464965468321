import React, { useState, useContext } from "react";
import InputField from "../../components/common/_loginInputField";
import { Col, Row } from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import CommonLandingPage from "./CommonLandingPage";
import ButtonLandingPage from "../LandingPages/CommonButtonLandingPage";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

/**
 * NOTE: Structured Component
 * @description This Component contains creation of new user.
 * @return {*}
 */

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const CreateAccount = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [username, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isUsernameValid, setIsUserNameValid] = useState(true);
  const [isEmailValid, setEmaildValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const handleUsernameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleEmailIdChange = (event) => {
    setEmailId(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const checkUserName = () => {
    const userNameRegex1 = new RegExp(/^[a-zA-Z0-9]{6,15}$/);
    const userNameRegex2 = new RegExp(/^\d+$/);

    if (
      userNameRegex1.test(username) === false ||
      userNameRegex2.test(username) === true
    ) {
      setIsUserNameValid(false);
      showMessage(
        "Username must be 6 to 15 characters, and alphanumeric or alphabets only.",
      );
      return false;
    } else {
      return true;
    }
  };

  const checkEmailId = () => {
    const emailRegex1 = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    );

    if (emailRegex1.test(emailId) === false) {
      setEmaildValid(false);
      showMessage("Please enter a valid email Id !");
      return false;
    } else {
      return true;
    }
  };

  const checkPassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/,
    );

    if (passwordRegex.test(password) === false) {
      setIsPasswordValid(false);
      showMessage(
        "Password must have 8 to 15 aplhanumeric characters, one capital letter, special characters allowed are !@#$%^&*_",
      );
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    let comparePassword;
    let checkPasswordResponse;

    setIsUserNameValid(true);
    setIsPasswordValid(true);

    let checkUserNameResponse = await checkUserName();

    let checkEmailIdResponse = await checkEmailId();

    if (checkUserNameResponse === true && checkEmailIdResponse === true) {
      checkPasswordResponse = await checkPassword();
    }

    if (checkUserNameResponse === true && checkPasswordResponse === true) {
      //This comparePassword will return 0 (if passwords match), else -1 will be returned.
      comparePassword = password.localeCompare(confirmPassword);
      if (comparePassword === 0) {
        setIsPasswordMatch(true);
      } else {
        setIsPasswordMatch(false);
        showMessage("The passwords do not match.");
      }
    }

    if (
      checkUserNameResponse === true &&
      checkPasswordResponse === true &&
      comparePassword === 0
    ) {
      try {
        let stringifiedData = JSON.stringify({
          clientId: env.REACT_APP_CLIENT_ID,
          emailId: emailId,
          createUser: true,
        });

        let sendOtpResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/generateOtp",
          stringifiedData,
        );

        if (sendOtpResponse.data.code === 200) {
          history.push({
            pathname: "/newUserVerification",
            username: username,
            password: password,
            emailId: emailId,
          });
        }
        showMessage("Failed to send OTP");
      } catch (error) {
        showMessage("Failed to send OTP");
      }
    }
  };

  return (
    <CommonLandingPage>
      <Row style={{ gap: "2.25rem" }}>
        <Col xs={12}>
          <Text
            style={{ color: "#F6F6F6", fontSize: "22px", lineHeight: "31px" }}
          >
            Welcome to Condense! Create an account to continue
          </Text>
        </Col>

        <Col xs={12}>
          <Row style={{ gap: "1rem" }}>
            <Col xs={12}>
              <InputField
                throwError={!isUsernameValid}
                name="username"
                placeholder="Enter Username"
                type="text"
                value={username}
                onChange={handleUsernameChange}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isEmailValid}
                name="email"
                placeholder="Enter Email Id"
                type="text"
                value={emailId}
                onChange={handleEmailIdChange}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isPasswordValid}
                name="password"
                placeholder="Enter Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isPasswordMatch}
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <ButtonLandingPage
            disabled={
              username === "" || password === "" || confirmPassword === ""
            }
            onClickEvent={() => handleSubmitClick()}
            buttonPlaceholder={"Continue"}
            linkLable={[
              <b
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  color: "#777777",
                }}
              >
                Already have an account?
                <Link
                  to="/Login"
                  className="d-flex align-items-center gap-2 text-white"
                >
                  <u>Login Now</u>
                </Link>
              </b>,
            ]}
          />
        </Col>
      </Row>
    </CommonLandingPage>
  );
};

export default withRouter(CreateAccount);
