import styled from "styled-components";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import CopyContentIcon from "../../assets/images/copy_content_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import { useState } from "react";
import { useEffect } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useContext } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { StyledButtonPrimary } from "../common/_buttonNewOne";

const CredentialModal = styled.div`
  background-color: #2f2f2f;
  width: 100%;
  height: 100%;
  /* max-height: 180px; */
  max-width: 260px;
  padding: 16px;
  box-shadow: 4px 4px 16px 4px #0000001c;
  border-radius: 8px;
  border: 1px solid #5e5e5e;
  overflow: auto;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
`;

const GrafanaCredentialsModal = ({ grafanaModalClose }) => {
  const { showMessage } = useContext(SnackbarContext);
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // On mount of this Modal, fetch the Grafana credentials
  useEffect(() => {
    (async () => {
      try {
        let getGrafanaCredentialsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/getGrafanaUser",
          JSON.stringify({
            url: "https://ncvocj.condense.zeliot.in",
          }),
        );
        if (getGrafanaCredentialsResponse.data.data) {
          setUserName(getGrafanaCredentialsResponse.data.data.username);
          setPassword(getGrafanaCredentialsResponse.data.data.password);
        } else throw new Error(getGrafanaCredentialsResponse.data.remarks);
      } catch (err) {
        console.log("Error in getGrafanaCredentials", err);
        showMessage("Failed to fetch grafana credentials", "error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions to copy username and password
  const handleUsernameCopy = () => {
    navigator.clipboard.writeText(userName);
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText(password);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "-300px",
          zIndex: "1000",
          top: "30px",
        }}
      >
        <CredentialModal>
          <CustomRow>
            <Column xs={10}>
              <TitleAndSubtitleContainer>
                <Text
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#777777",
                    fontWeight: "500",
                  }}
                >
                  Username
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#F6F6F6",
                    lineHeight: "18px",
                    lineSpacing: "0.02em",
                  }}
                >
                  {userName}
                </Text>
              </TitleAndSubtitleContainer>
            </Column>
            <Column xs={1.5}>
              <img
                src={CopyContentIcon}
                alt="Copy Icon"
                onClick={handleUsernameCopy}
                style={{ cursor: "pointer" }}
              />
            </Column>
          </CustomRow>
          <CustomRow style={{ marginTop: "16px" }}>
            <Column xs={10}>
              <TitleAndSubtitleContainer>
                <Text
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#777777",
                    fontWeight: "500",
                  }}
                >
                  Password{" "}
                  <span>
                    <img
                      src={
                        showPassword ? ShowPasswordIcon : DontShowPasswordIcon
                      }
                      alt="Show Password"
                      style={{
                        marginLeft: "8px",
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </span>
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#F6F6F6",
                    lineHeight: "18px",
                    lineSpacing: "0.02em",
                  }}
                >
                  {showPassword ? password : "*".repeat(password.length)}
                </Text>
              </TitleAndSubtitleContainer>
            </Column>
            <Column xs={1.5}>
              <img
                src={CopyContentIcon}
                alt="Copy Icon"
                onClick={handlePasswordCopy}
                style={{ cursor: "pointer" }}
              />
            </Column>
          </CustomRow>
          <CustomRow style={{ marginTop: "16px" }}>
            <StyledButtonPrimary
              onClick={() => {
                const baseUrl = env.REACT_APP_URL;
                const grafanaUrl = `${baseUrl}grafana/login`;
                window.open(grafanaUrl, "_blank");
                grafanaModalClose();
              }}
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
              }}
            >
              Open Grafana Dashboard
            </StyledButtonPrimary>
          </CustomRow>
        </CredentialModal>
      </div>
    </div>
  );
};

export default GrafanaCredentialsModal;
