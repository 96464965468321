import React from "react";
import { Row, Col } from "reactstrap";
import Typography from "./_typography";
import Logout from "../../assets/images/logout.png";
import { Redirect, useHistory } from "react-router-dom";

const AccountDropDown = () => {
  const history = useHistory();
  const logoutListener = () => {
    for (let key in localStorage) {
      localStorage.removeItem(key);
    }
    history.push("/");
  };
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} className="accountListDropDown" onClick={logoutListener}>
            <Row>
              <Col xs={3}>
                <img src={Logout} height="30px" />
              </Col>
              <Col xs={6} className="accountListText">
                <Typography
                  color={"#FAFAFA"}
                  tag={"p-medium"}
                  text={"Logout"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountDropDown;
