// import React, { useContext, useEffect, useState } from "react";
// import { SnackbarContext } from "../../layouts/Context/snackBarContext";
// import { Col, Row, Container } from "reactstrap";
// import styled from "styled-components";
// import Typography from "./_typography";
// import ErrorIcon from "../../assets/images/Vector copy.svg";

// const StyledTopSnackBar = styled.div`
//   position: absolute;
//   font-family: "Articulat CF Medium";
//   align-items: center;
//   border-radius: 8px;
//   width: 320px;
//   height: 96px;
//   left: 16px;
//   bottom: 16px;
//   background: ${(props) => (props.type === "success" ? "#2D4135" : "#4d3131")};
//   z-index: 2;
//   border: 1.5px solid
//     ${(props) => (props.type === "success" ? "#259D55" : "#ea3a3a")};
// `;

// const TopSnackBar = (props) => {
//   const { message, showMessage, messageType } = useContext(SnackbarContext);

//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     if (message) {
//       setOpen(true);
//       const timer = setTimeout(() => {
//         setOpen(false);
//         showMessage("");
//       }, 3000);
//       return () => clearTimeout(timer);
//     }
//   }, [message, showMessage]);

//   return (
//     <StyledTopSnackBar
//       style={{ display: open ? "block" : "none" }}
//       type={messageType}
//     >
//       <Container>
//         <Row>
//           <Col
//             className="snackbar-container"
//             xs={12}
//             style={{ margin: "16px" }}
//           >
//             <Col
//               className="Icon-message"
//               style={{
//                 display: "flex",
//                 marginBottom: "4px",
//                 letterSpacing: "0.5px",
//                 gap: "5px",
//               }}
//             >
//               <img src={ErrorIcon} alt="error" />
//               <Typography text="Error" color={"#F6F6F6"} tag={"p-bold-large"} />
//             </Col>
//             <Col style={{ fontSize: "12px" }}>
//               <Typography text={message} color={"#F6F6F6"} tag={"p-large"} />
//             </Col>
//           </Col>
//         </Row>
//       </Container>
//     </StyledTopSnackBar>
//   );
// };

// export default TopSnackBar;

import React, { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Col, Row, Container } from "reactstrap";
import styled from "styled-components";
import Typography from "./_typography";
import ErrorIcon from "../../assets/images/Vector copy.svg";
import SuccessIcon from "../../assets/images/Frame.svg";
import CloseIcon from "../../assets/images/Close.svg";

const StyledTopSnackBar = styled.div`
  position: absolute;
  font-family: "Articulat CF Medium";
  align-items: center;
  border-radius: 8px;
  width: 320px;
  height: 96px;
  left: 16px;
  bottom: 16px;
  background: ${(props) => (props.type === "success" ? "#2D4135" : "#4d3131")};
  z-index: 2;
  border: 1.5px solid
    ${(props) => (props.type === "success" ? "#259D55" : "#ea3a3a")};
`;

const TopSnackBar = () => {
  const { message, showMessage, messageType } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        showMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message, showMessage]);

  const handleClose = () => {
    setOpen(false);
    showMessage("");
  };

  return (
    <StyledTopSnackBar
      style={{ display: open ? "block" : "none" }}
      type={messageType}
    >
      <Container>
        <Row>
          <Col
            className="snackbar-container"
            xs={12}
            style={{ margin: "16px", position: "relative" }}
          >
            <Col
              className="Icon-message"
              style={{
                display: "flex",
                marginBottom: "4px",
                letterSpacing: "0.5px",
                gap: "9px",
                alignItems: "center",
              }}
            >
              {messageType === "success" ? (
                <>
                  <img src={SuccessIcon} alt="success" />
                  <Typography
                    text="Way to Go!"
                    color={"#F6F6F6"}
                    tag={"p-bold-large"}
                  />
                  <img
                    src={CloseIcon}
                    alt="Close"
                    onClick={handleClose}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      marginLeft: "270px",
                    }}
                  />
                </>
              ) : (
                <>
                  <img src={ErrorIcon} alt="error" />
                  <Typography
                    text="Something went wrong!"
                    color={"#F6F6F6"}
                    tag={"p-bold-large"}
                  />
                  <img
                    src={CloseIcon}
                    alt="Close"
                    onClick={handleClose}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      marginLeft: "270px",
                    }}
                  />
                </>
              )}
            </Col>
            <Col style={{ fontSize: "9px", marginTop: "8px" }}>
              <Typography text={message} color={"#F6F6F6"} tag={"p-small"} />
            </Col>
          </Col>
        </Row>
      </Container>
    </StyledTopSnackBar>
  );
};

export default TopSnackBar;
