import moment from "moment";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import SettingsIcon from "../../../src/assets/images/Settings.svg";
import NotFound from "../../../src/assets/images/photo.png";
import styled from "styled-components";
import CustomRow from "../common/_customRow";
import ExpandLeft from "../../assets/images/expand_more_24px.svg";
import Column from "../common/_customColumn";
import { Col } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Block from "../../assets/images/Blogs background.png";
/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const MainContainer = styled.div`
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding-bottom: 16px;
  gap: 16px;
`;
const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
`;
const ProfileText = styled.p`
  font-size: 20px;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #f6f6f6;
  line-height: 27px;
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const CreateCard = styled.p`
  color: #777777;
  font-size: 12px;
  font-family: "Articulat CF Medium";
  margin: 0px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  font-family: "Articulat CF Medium";
  line-height: 20.8px;
  color: #b0b0b0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;
const ReadText = styled.p`
  color: #47ccd6;
  font-size: 14px;
  font-weight: 500;
  font-family: "Articulat CF Medium";
  margin: 0px;
`;

const WorkspaceBlock = (props) => {
  let { eachBlog } = props;
  return (
    <MainContainer>
      <img
        src={eachBlog.image_url || Block}
        height="162px"
        style={{
          width: "100%",
          borderRadius: "12px 12px 0 0",
          objectFit: "cover",
        }}
      ></img>
      <NameDiv style={{ minHeight: "54px" }}>
        <ProfileText className="multiline-ellipsis ">
          {eachBlog.title}
        </ProfileText>
      </NameDiv>
      <NameDiv style={{ minHeight: "60px" }}>
        <DescriptionText className="multiline-ellipsis ">
          {eachBlog.description}
        </DescriptionText>
      </NameDiv>
      <CustomRow style={{ padding: "8px 16px 0px 16px" }}>
        <CreateCard>{`${moment(Date.now()).format("DD MMM YYYY")}`}</CreateCard>
        <CustomRow style={{ gap: "8px" }}>
          <a
            href={eachBlog.blog_url}
            target="_blank"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ReadText>Read More</ReadText>
            <img src={ExpandLeft}></img>
          </a>
        </CustomRow>
      </CustomRow>
    </MainContainer>
  );
};

export default WorkspaceBlock;
