import styled, { keyframes } from "styled-components";
import ConnectorIcon from "../../../assets/images/connector_node_preview_icon.svg";
import SettingsIcon from "../../../assets/images/settings_icon.svg";
import RunningIcon from "../../../assets/images/loadingicon.svg";
import RunningApplicationIcon from "../../../assets/images/node_status_running_icon.svg";
import RunningStoreIcon from "../../../assets/images/store_node_status.svg";
import InspectIcon from "../../../assets/images/node_status_error_icon.svg";
import ErrorIcon from "../../../assets/images/node_status_error_icon.svg";
import { useMemo, useState } from "react";
import { Handle, Position } from "reactflow";

const CardContainer = styled.div`
  width: 256px;
  height: 126px;
  max-width: 256px;
  max-height: 126px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 0px 40px 0px #00000029;
`;

const LeftLine = styled.div`
  width: 4px;
  background-color: #47ccd6;
  border-radius: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: baseline;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusContainer = styled.div`
  height: 20px;
  border: 1px solid #f6f6f6;
  padding: 0px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(246, 246, 246, 0.16);

  &.Running {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getRunningColor(props.category)};
  }

  &.Publishing {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getRunningColor(props.category)};
  }

  &.Inspect {
    background: rgba(237, 182, 46, 0.16);
    border-color: #edb62e;
  }

  &.Error {
    background: rgba(234, 58, 58, 0.16);
    border-color: #ea3a3a;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(76, 74, 74, 0.1);
  border-top: 2px solid ${(props) => getRunningColor(props.category)};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 0.7s linear infinite;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TitleAndIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 190px;
`;

const RunningStatus = ({ category }) => {
  const getRunningIcon = (category) => {
    switch (category) {
      case "Store Connector":
        return RunningStoreIcon;
      case "Application":
        return RunningApplicationIcon;
      default:
        return RunningIcon;
    }
  };

  return (
    <NodeStatusDiv>
      <img src={getRunningIcon(category)} alt="Running" />
      <Text style={{ fontSize: "10px", color: getRunningColor(category) }}>
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const PublishingStatus = ({ category }) => {
  const getPublishingIcon = (category) => {
    switch (category) {
      case "Store Connector":
        return (
          <Spinner style={{ borderTopColor: getRunningColor(category) }} />
        );
      case "Application":
        return (
          <Spinner style={{ borderTopColor: getRunningColor(category) }} />
        );
      default:
        return <Spinner />;
    }
  };

  return (
    <NodeStatusDiv>
      {getPublishingIcon(category)}
      <Text style={{ fontSize: "10px", color: getRunningColor(category) }}>
        Publishing
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <img src={InspectIcon} alt="Inspect" />
    <Text style={{ fontSize: "10px", color: "#EDB62E" }}>Inspect</Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <img src={ErrorIcon} alt="Error" />
    <Text style={{ fontSize: "10px", color: "#EA3A3A" }}>Error</Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category) => {
  switch (status) {
    case "Running":
      return <RunningStatus category={category} />;
    case "Publishing":
      return <PublishingStatus category={category} />;
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      // return <Spinner />;
      return <PublishingStatus category={category} />;
  }
};

const getRunningColor = (category) => {
  switch (category) {
    case "Store Connector":
      return "#F6F6F6";
    case "Application":
      return "#C7A6F1";
    default:
      return "#47ccd6";
  }
};

const getBackgroundColor = (category) => {
  switch (category) {
    case "Store Connector":
      return "#F6F6F629";
    case "Application":
      return "#C0BBFB29";
    default:
      return "#47ccd629";
  }
};

const PipelineConnectorCard = (props) => {
  const {
    title,
    connectionType,
    category,
    name,
    interfaces,
    isConfigure,
    status = "",
  } = props.data;

  const [connection, setConnection] = useState({ left: false, right: false });

  useMemo(() => {
    const left = interfaces.some((topic) => topic.direction === "input");
    const right = interfaces.some((topic) => topic.direction === "output");
    setConnection({ left, right });
  }, [interfaces]);

  return (
    <>
      {connection.left && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="target"
          position={Position.Left}
        />
      )}
      <CardContainer>
        <LeftLine style={{ backgroundColor: getRunningColor(category) }} />
        <ContentContainer>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndIconRow>
              <IconContainer>
                <img src={ConnectorIcon} alt="Connector Icon" />
              </IconContainer>
              <TitleAndSubtitleContainer>
                {title && (
                  <Text
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#F6F6F6",
                      fontFamily: "Articulat CF Medium",
                      letterSpacing: "0.5px",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Text>
                )}
                {name && (
                  <Text
                    style={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      color: "#777777",
                      fontFamily: "Articulat CF Medium",
                      letterSpacing: "0.5px",
                      fontWeight: "bold",
                    }}
                  >
                    {name}
                  </Text>
                )}
              </TitleAndSubtitleContainer>
            </TitleAndIconRow>
            <IconContainer style={{ width: "20px" }}>
              <img src={SettingsIcon} alt="Connector Icon" />
            </IconContainer>
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  letterSpacing: "0.5px",
                  fontWeight: "bold",
                }}
              >
                Connector Category
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  color: getRunningColor(category),
                }}
              >
                {category}
              </Text>
            </TitleAndSubtitleContainer>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  letterSpacing: "0.5px",
                  fontWeight: "bold",
                }}
              >
                Connection Type
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  color: getRunningColor(category),
                }}
              >
                {connectionType}
              </Text>
            </TitleAndSubtitleContainer>
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <Text
              style={{ fontSize: "12px", lineHeight: "16px", color: "#47CCD6" }}
            >
              {isConfigure ? "Configure" : ""}
            </Text>
            <NodeStatusContainer
              className={status}
              category={category}
              style={{ borderColor: getRunningColor(category) }}
            >
              {displayNodeStatus(status, category)}
            </NodeStatusContainer>
          </ContentRow>
        </ContentContainer>
      </CardContainer>
      {connection.right && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="source"
          position={Position.Right}
        />
      )}
    </>
  );
};

export default PipelineConnectorCard;
